@import "../../layout/variables";
@import "../../layout/mixins";
.table-wrapper {
    margin-top: $px-12;
    &.MuiPaper-rounded {
        border-radius: $px-12;
    }
    .table-container {
        padding: $px-24 $px-20 $px-30;
        .header {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            .right-container {
                margin-left: auto;
                display: flex;
                div+div {
                    margin-left: $px-20;
                }
            }
        }
        .body {
            margin-bottom: $px-20;
            .loader {
                margin-top: 50px;
                margin-bottom: 50px;
            }
            >div {
                &::-webkit-scrollbar {
                    @include scrollbar;
                }
                &::-webkit-scrollbar-track {
                    @include scrollbar-track;
                }
                &::-webkit-scrollbar-thumb {
                    @include scrollbar-thumb;
                }
            }
            .rdt_Table {
                border-top: 1px solid rgba(0, 0, 0, 0.12);
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                .rdt_TableHead {
                    .rdt_TableCol {
                        font-size: 16px;
                        color: $text-color-1;
                        padding: 16px;
                        [role*="columnheader"]>div {
                            line-height: 1.6;
                        }
                    }
                }
                .rdt_TableBody {
                    .rdt_TableCell {
                        font-weight: 400;
                        font-size: 16px;
                        color: lighten($text-color-3, 9%);
                        padding: 8px 16px;
                        .ellipsis {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
                .rdt_TableCol_Sortable{
                    > span{
                        font-size: 8px;
                        display: inline-block;
                        margin-left: 4px;
                        color: rgba(0,0,0,.4)
                    }

                    &:hover {
                        color: rgba(0,0,0,1);
                    }
                }
            }
        }
    }
    .table-search {
        position: relative;
        width: 100%;
        max-width: 484px;
        min-width: 480px;
        // .icon {
        //     position: absolute;
        //     width: 18px;
        //     fill: lighten($black, 70%);
        //     top: 50%;
        //     transform: translateY(-50%);
        //     left: 18px;
        // }
        .MuiFormControl-root {
            // width: calc( 100% - 118px);
            width: 100%;
            .MuiOutlinedInput-root {
                border-radius: 999px;
                // margin-right: 10px;
                .MuiOutlinedInput-input {
                    // padding: 19px 14px 21px 48px;
                    padding: 19px 24px 21px;
                }
            }
        }
        .MuiButton-root {
            padding: 8px 30px;
        }
    }
    .pagination {
        text-align: center;
        .MuiButton-root {
            min-width: 26px;
            font-weight: 400;
            color: $text-color-3;
            font-size: $px-18;
            line-height: 1.4;
            padding-left: 6px;
            padding-right: 6px;
            &.Mui-disabled {
                opacity: 0.4;
            }
            &.active {
                color: $purple-1;
                font-weight: 700;
            }
        }
        .first-btn {
            padding: 6px 3px;
            padding-right: 4px;
            min-width: 12px;
            svg {
                width: 16px;
                height: 22px;
            }
        }
        .prev-btn {
            padding: 6px 3px;
            padding-right: 4px;
            margin-right: 6px;
            min-width: 12px;
            svg {
                width: 10px;
                height: 22px;
            }
        }
        .next-btn {
            padding: 6px 3px;
            padding-left: 4px;
            margin-left: 6px;
            min-width: 12px;
            svg {
                width: 10px;
                height: 22px;
            }
        }
        .last-btn {
            padding: 6px 3px;
            padding-left: 4px;
            min-width: 12px;
            svg {
                width: 16px;
                height: 22px;
            }
        }
    }
    .records-per-page.MuiFormControl-root {
        align-items: center;
        flex-direction: row;
        >label {
            font-size: $px-16;
            color: lighten($black, 63%);
        }
        .MuiInputBase-root {
            margin-top: 0;
            margin-right: 16px;
            .MuiOutlinedInput-input {
                padding: 10px 34px 11px 14px;
            }
            .MuiInputBase-input {
                height: 100%;
                color: $text-color-1;
            }
            .MuiSelect-icon {
                top: calc(50% - 9px);
                width: $px-18;
                height: $px-18;
                fill: $text-color-1;
            }
        }
    }
    .table-filter {
        .filter-btn {
            color: $text-color-1;
            border-radius: $px-4;
            padding: 5px 10px;
            padding-right: 30px;
            font-weight: 400;
            font-size: $px-16;
            position: relative;
            min-width: 130px;
            justify-content: left;
            &.has-active {
                background-color: $purple-1;
                border-color: $purple-1;
                color: $white;
                .fiter-icon,
                .arrow-down {
                    fill: $white;
                }
            }
            .fiter-icon {
                width: $px-18;
                height: $px-18;
                fill: $text-color-1;
                margin-right: $px-8;
            }
            .arrow-down {
                top: calc(50% - 9px);
                width: $px-18;
                height: $px-18;
                fill: $text-color-1;
                position: absolute;
                right: $px-6;
            }
        }
    }
}

.table-filter-menu {
    .header {
        border-bottom: 1px solid $light-gray-2;
        padding: $px-6 $px-8;
        display: flex;
        button {
            color: $purple-1;
            font-size: $px-16;
            padding: 4px 10px;
            font-weight: 400;
        }
        button+button {
            margin-left: auto;
        }
    }
    .body {
        padding: 0 $px-18;
        min-width: 244px;
        .section {
            .title {
                font-weight: 600;
                color: $text-color-1;
                display: block;
                font-size: $px-14;
                margin: $px-14 0 $px-10;
            }
        }
        .applications {
            .title {
                margin-bottom: $px-8;
            }
            .MuiFormGroup-root {
                .MuiIconButton-root {
                    padding: 6px 9px;
                }
                .MuiCheckbox-root {
                    color: rgba(0, 0, 0, 0.26);
                    &.Mui-checked {
                        color: $light-gray-2;
                        .MuiIconButton-label {
                            position: relative;
                            svg {
                                position: relative;
                                z-index: 1;
                            }
                            &::after {
                                content: "";
                                background: $purple-1;
                                position: absolute;
                                width: $px-14;
                                height: $px-14;
                                top: 5px;
                                left: 5px;
                            }
                        }
                    }
                }
                .MuiTypography-root {
                    @include remove-highlight;
                    position: relative;
                    bottom: 1px;
                }
            }
        }
        .time-of-creation {
            .title {
                margin-top: $px-10;
            }
            .form-control {
                .MuiIconButton-root {
                    padding: 8px 10px;
                    position: relative;
                    top: 1px;
                    svg {
                        width: $px-20;
                        height: $px-20;
                    }
                }
                .MuiInputBase-root {
                    flex-direction: row-reverse;
                    .MuiInputBase-input {
                        padding: 12px 0;
                        font-size: $px-14;
                    }
                    .MuiOutlinedInput-notchedOutline {
                        border-radius: 999px;
                    }
                }
                .MuiFormControl-root {
                    margin: 0;
                    width: 100%;
                }
                label {
                    display: block;
                    font-weight: 400;
                    margin-bottom: $px-4;
                    font-size: $px-14;
                }
                +.form-control {
                    margin-top: $px-10;
                }
            }
        }
    }
    .footer {
        padding: $px-14 $px-18;
        .apply-btn {
            font-size: $px-14;
        }
    }
    .MuiMenu-paper {
        margin-top: 5px;
        max-height: calc(100% - 300px);
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
        &::-webkit-scrollbar {
            @include scrollbar;
        }
        &::-webkit-scrollbar-track {
            @include scrollbar-track;
        }
        &::-webkit-scrollbar-thumb {
            @include scrollbar-thumb;
        }
        ul {
            padding-right: 0 !important;
            width: 100% !important;
        }
    }
    .MuiList-root {
        padding: 0;
    }
}