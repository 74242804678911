@import "../../../layout/variables";
@import "../../../layout/mixins";

.upload-form{
    align-items: start;
    margin-top: $px-20;
    position: relative;
    padding-top: $px-20;
    border-top: 1px solid rgba(0,0,0,0.1);
  }

  .dropzone-wrapper{
    position: relative;
    width: 100%;

    .additional-text{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      font-size: $px-14;
      font-weight: 500;
      padding-top: 62px;
      margin: 0;
      color: lighten($text-color-3, 10%);
      pointer-events: none;
    }
  }

  .drop-image{
    border: 1px solid transparent;
    // min-height: 140px;
    height: 140px;
    width: 628px;
    background-color: $light-purple-2;
    position: relative;
    background-image: url("data:image/svg+xml,%3csvg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%' height='100%' fill='none' rx='6' ry='6' stroke='rgba(0,0,0,0.2)' stroke-width='2' stroke-dasharray='6%2c 10' stroke-dashoffset='23' stroke-linecap='square'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    border-radius: 6px;

    &.error,
    &.error:hover{
      border-color: $red-1;
    }

    &:hover{
      background-image: none;
      border-color: rgba(0, 0, 0, 0.87);
    }

    // &:focus{
    //   border-color: $purple-1;
    //   border-width: 2px;
    // }

    .MuiDropzoneArea-textContainer{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      padding-bottom: 24px;
      pointer-events: none;

      p{
        color: $purple-1;
        font-size: $px-14;
        margin: 0;
        margin-bottom: $px-16;
        font-weight: 400;
      }
      svg{
        width: 16px;
        height: 16px;
        fill: $purple-1;
        margin-bottom: $px-2;
      }
    }

    .priview-container{
      width: 100%;
      height: 100%;
      margin: 0;
      display: block;
      position: absolute;

      .priview-item{
        padding: 0;
        width: 98%;
        max-width: none;
        position: relative;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%);
        background-color: $light-purple-2;

        &:hover{
          img{
            opacity: 1;
          }
        }
        
        img{
          height: 120px;
          vertical-align: middle;
          border-radius: 0;
          box-shadow: none;
        }
        button{
          opacity: 1;
          top: auto;
          right: 12px;
          bottom: 2px;
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
          background-color: $purple-1;

          svg{
            fill: $white;
          }
        }
      }
    }
  }

  [data-column-id="rate"].rdt_TableCol_Sortable {
    color: #C0C0C0;
  }

  [data-column-id="timestamp"].rdt_TableCol_Sortable {
    color: #C0C0C0;
  }

  [data-column-id="rate"].rdt_TableCell {
    color: #C0C0C0 !important;
  }

  [data-column-id="timestamp"].rdt_TableCell {
    color: #C0C0C0 !important;
  }

  [data-column-id="fees"].rdt_TableCell {
    justify-content: space-evenly;
  }