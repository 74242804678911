@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

@mixin horizontal-list {
  @include reset-list;
  li {
    display: inline-block;
  }
}

@mixin remove-highlight {
  -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
      -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  -webkit-tap-highlight-color: transparent;
}

// SCROLL BAR =========================================
@mixin scrollbar {
  width: 8px;
  height: 8px;
  background: #fcfcfc;
  border-radius: 10px;
}

@mixin scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background: #fcfcfc;
}

@mixin scrollbar-thumb {
  border-radius: 10px;
  // background: #ebebeb;
  background-color: lighten(#7b79eb, 10%);	 
	background-image: -webkit-linear-gradient(
    45deg, 
    rgba(255, 255, 255, .2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .2) 50%,
    rgba(255, 255, 255, .2) 75%,
    transparent 75%,
    transparent
  )
}