@import '../../layout/variables';
@import '../../layout/mixins';

.page-sidebar{
  background: $white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 1;

  .drawer-paper{
    width: 230px;
    z-index: 1000;
    position: relative;
    min-height: calc( 100vh - 70px );
    border: none;
  }

  .menu-list{
    padding-top: $px-20;
    height: calc( 100vh - 70px );

    .list-item{
      border-bottom: 1px solid $light-gray-2;

      .MuiListItemText-root{
        margin-left: $px-8;
      }

      &.active{
        background: $light-purple-2;
        position: relative;

        .MuiListItemText-root span{
          color: darken($purple-1, 2%);
        }

        .MuiListItem-root::after{
          content: '';
          position: absolute;
          display: inline-block;
          width: 5px;
          height: 100%;
          top: 0;
          left: 0;
          background: $purple-1;
        }
      }

      .MuiListItemText-root span{
        font-size: $px-14;
        font-weight: 400;
        color: $text-color-1;
      }

      &.collapsible-item{
        .MuiCollapse-container{
          background: darken($white, 4%);
        }

        .nested-list{
          .MuiListItemText-root{
            margin-left: $px-20;
            span{
              font-size: $px-12;
              color: $text-color-1;
            }
          }
          .MuiListItem-root::after{
            content: none;
          }

          a{
            border-top: 1px solid darken($light-gray-2, 2%);

            &.active-nested .MuiListItemText-root span{
              color: darken($purple-1, 2%);
            }
          }
          .MuiListItem-root.Mui-disabled{
            opacity: 1
          }
        }
      }
    }
  }
}