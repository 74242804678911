.paper-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  min-height: 200px;
}

.paper {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(black, 0.2);
  padding: 0;
  width: 800px;
  min-height: 400px;
  text-align: center;
  box-sizing: border-box;
}

.header {
  padding: 24px 30px 16px;
  border-bottom: 1px solid rgba(black, 0.12);
  display: flex;
  justify-content: center;

  .title {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
  }
}

.edit-form-control {
  margin-left: 2vw !important;

  &.ace-date-range {
    flex-direction: row;
    width: 705px;

    & > * {
      flex: 50%;

      &:nth-child(2) {
        margin-left: 1rem;
      }
    }
  }
}

.textfield-item-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 3vh;
  gap: 10px;
}

.form-label {
  display: flex;
  justify-content: flex-start !important;
  align-items: center !important;
}

.footer {
  padding: 14px 30px 30px;
  display: flex;
  justify-content: center;

  button {
    min-width: 150px;
    padding: 8px 16px;
  }

  button + button {
    margin-left: 10px;
  }

  .submit-btn {
    position: relative;

    .text {
      position: relative;
      right: 0;
      transition: right 0.4s ease-in-out;
    }

    &.Mui-disabled .text {
      right: 10px;
    }

    &.empty-fields {
      &.Mui-disabled .text {
        right: 0;
      }
    }

    .loader {
      position: relative;
    }

    &.MuiButton-contained.Mui-disabled {
      color: rgba(white, 0.8);
      background-color: lighten(purple, 5%);
    }
  }

  .exit-btn {
    background-color: #777;
    color: white;

    &:hover {
      background-color: darken(#777, 13%);
    }

    &.MuiButton-contained.Mui-disabled {
      color: rgba(white, 0.8);
      background-color: lighten(#777, 20%);
    }
  }

  .error-message {
    padding: 20px 0 0;
    border-color: transparent;
    justify-content: center;
    color: darken(red, 9%);

    .MuiAlert-icon {
      color: red;
    }
  }
}

.upload-style {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  height: 184px;
  margin-left: 40px;

  padding: 16px 4.8px 16px 55.5px;
  border-radius: 10px;
  border: dashed 1px #c4c5d1;
  background-color: #f1f0fb;

  &__error {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 184px;

    padding: 16px 4.8px 16px 55.5px;
    border-radius: 10px;
    border: dashed 1px #ed1f24;
    background-color: #ffeced;
  }

  &__label {
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 0.38px;
    text-align: left;
    color: #181818;
    margin: 0 0 0 40px;
  }
}

.upload-style-error {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 184px;

  padding: 16px 4.8px 16px 55.5px;
  border-radius: 10px;
  border: dashed 1px #ed1f24;
  background-color: #ffeced;
}

.image-style {
  width: 100%;
  height: 128px;
  margin-right: 2rem;
}

.upload-label {
  width: 260px;
  height: 17px;
  color: #84868d;
  padding: 0.5rem;
  font-family: sans-serif;
  font-weight: 600;
  border: none;
  cursor: pointer;
  margin-right: 2rem;
}

.upload-button {
  width: 140px;
  height: 33px;
  margin: 20px 85px 0 46px;
  padding: 8px 30px;
  border-radius: 8px;
  background-color: #3542d8;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 0.35px;
  text-align: left;
  color: #fff;
  border: none;
  cursor: pointer;
}

.uploaded-image-style {
  width: 186px;
  height: 160px;
  transform: translate(-20px, 0);
}

.after-upload-style {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.delete-icon-style:hover {
  opacity: 0.5;
  cursor: pointer;
}
