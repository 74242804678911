@import "../../../layout/variables";
@import "../../../layout/mixins";

.widget-container {
  display: flex;
  justify-content: space-between;
  width: 102%;
}

.card-paper {
  padding: $px-18;
  border-radius: $px-12;
  background-repeat: no-repeat;
  background-position: center right 30px;
  background-size: 90px;
  background-image: url(../../../assets/images/icon-ace.svg) !important;
  height: 100%;

  .MuiCard-root {
    height: 100% !important;
  }

  .title {
    font-size: $px-18;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-bottom: $px-10;

    .icon {
      display: inline-block;
      vertical-align: middle;
      background-color: $purple-1;
      padding: $px-6 $px-6 5px;
      border-radius: $px-10;
      margin-right: $px-10;

      svg {
        width: $px-24;
      }
    }
  }

  .value {
    font-size: $px-42;
    font-weight: 700;
  }
}

.widget-numbers{
  font-size: 25px;
  font-weight: 700; 
}