// react-slick

.slick-dots li{
  margin: 0 2px;
}

.slick-dots li button:before{
  font-size: $px-8;
}

.slick-dots li.slick-active button:before{
  color: #448ccb;
}

.slick-dots li button:hover:before, 
.slick-dots li button:focus:before{
  color: #448ccb;
}