// WIDTH ================================================

.w-100 {
  width: 100% !important;
}

// DISPLAY ==============================================

.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}

.d-inline-block {
  display: inline-block !important;
}

// SPACING ==============================================

// padding bottom
.pb-6 {
  padding-bottom: $px-6 !important;
}
.pb-8 {
  padding-bottom: $px-8 !important;
}
.pb-10 {
  padding-bottom: $px-10 !important;
}
.pb-12 {
  padding-bottom: $px-12 !important;
}
.pb-14 {
  padding-bottom: $px-14 !important;
}
.pb-16 {
  padding-bottom: $px-16 !important;
}
.pb-18 {
  padding-bottom: $px-18 !important;
}
.pb-20 {
  padding-bottom: $px-20 !important;
}
.pb-22 {
  padding-bottom: $px-22 !important;
}
.pb-24 {
  padding-bottom: $px-24 !important;
}
.pb-26 {
  padding-bottom: $px-26 !important;
}
.pb-28 {
  padding-bottom: $px-28 !important;
}
.pb-30 {
  padding-bottom: $px-30 !important;
}
.pb-32 {
  padding-bottom: $px-32 !important;
}
.pb-34 {
  padding-bottom: $px-34 !important;
}
.pb-36 {
  padding-bottom: $px-36 !important;
}
.pb-38 {
  padding-bottom: $px-38 !important;
}
.pb-40 {
  padding-bottom: $px-40 !important;
}
.pb-42 {
  padding-bottom: $px-42 !important;
}
.pb-44 {
  padding-bottom: $px-44 !important;
}
.pb-46 {
  padding-bottom: $px-46 !important;
}
.pb-48 {
  padding-bottom: $px-48 !important;
}
.pb-50 {
  padding-bottom: $px-50 !important;
}
.pb-60 {
  padding-bottom: $px-60 !important;
}
.pb-70 {
  padding-bottom: $px-70 !important;
}
.pb-80 {
  padding-bottom: $px-80 !important;
}

// margin bottom
.mb-4 {
  margin-bottom: $px-4 !important;
}
.mb-6 {
  margin-bottom: $px-6 !important;
}
.mb-8 {
  margin-bottom: $px-8 !important;
}
.mb-10 {
  margin-bottom: $px-10 !important;
}
.mb-12 {
  margin-bottom: $px-12 !important;
}
.mb-14 {
  margin-bottom: $px-14 !important;
}
.mb-16 {
  margin-bottom: $px-16 !important;
}
.mb-18 {
  margin-bottom: $px-18 !important;
}
.mb-20 {
  margin-bottom: $px-20 !important;
}
.mb-22 {
  margin-bottom: $px-22 !important;
}
.mb-24 {
  margin-bottom: $px-24 !important;
}
.mb-26 {
  margin-bottom: $px-26 !important;
}
.mb-28 {
  margin-bottom: $px-28 !important;
}
.mb-30 {
  margin-bottom: $px-30 !important;
}
.mb-32 {
  margin-bottom: $px-32 !important;
}
.mb-34 {
  margin-bottom: $px-34 !important;
}
.mb-36 {
  margin-bottom: $px-36 !important;
}
.mb-38 {
  margin-bottom: $px-38 !important;
}
.mb-40 {
  margin-bottom: $px-40 !important;
}
.mb-42 {
  margin-bottom: $px-42 !important;
}
.mb-44 {
  margin-bottom: $px-44 !important;
}
.mb-46 {
  margin-bottom: $px-46 !important;
}
.mb-48 {
  margin-bottom: $px-48 !important;
}
.mb-50 {
  margin-bottom: $px-50 !important;
}
.mb-60 {
  margin-bottom: $px-60 !important;
}
.mb-70 {
  margin-bottom: $px-70 !important;
}
.mb-80 {
  margin-bottom: $px-80 !important;
}

// margin left
.ml-6 {
  margin-left: $px-6 !important;
}
.ml-8 {
  margin-left: $px-8 !important;
}
.ml-10 {
  margin-left: $px-10 !important;
}
.ml-12 {
  margin-left: $px-12 !important;
}
.ml-14 {
  margin-left: $px-14 !important;
}
.ml-16 {
  margin-left: $px-16 !important;
}
.ml-18 {
  margin-left: $px-18 !important;
}
.ml-20 {
  margin-left: $px-20 !important;
}
.ml-22 {
  margin-left: $px-22 !important;
}
.ml-24 {
  margin-left: $px-24 !important;
}
.ml-26 {
  margin-left: $px-26 !important;
}
.ml-28 {
  margin-left: $px-28 !important;
}
.ml-30 {
  margin-left: $px-30 !important;
}
.ml-32 {
  margin-left: $px-32 !important;
}
.ml-34 {
  margin-left: $px-34 !important;
}
.ml-36 {
  margin-left: $px-36 !important;
}
.ml-38 {
  margin-left: $px-38 !important;
}
.ml-40 {
  margin-left: $px-40 !important;
}

// COLORED TEXT ==============================================

.text-purple{
  color: $purple-1 !important;
}