@import "../../../layout/variables";
@import "../../../layout/mixins";

.widget-container {
  display: flex;
  justify-content: space-between;
}

.card-paper {
  padding: $px-18;
  border-radius: $px-12;
  background-repeat: no-repeat;
  background-position: center right 30px;
  background-size: 90px;
  background-image: url("../../../assets/images/dappstore-icon-gray.svg");

  .title {
    font-size: $px-18;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-bottom: $px-10;

    .icon {
      display: inline-block;
      vertical-align: middle;
      background-color: $purple-1;
      padding: $px-6 $px-6 5px;
      border-radius: $px-10;
      margin-right: $px-10;

      svg {
        width: $px-24;
      }
    }
  }

  .value {
    font-size: $px-42;
    font-weight: 700;
  }
}

.widget-numbers {
  font-size: 25px;
  font-weight: 700;
}

.wallet-address-list {

  .table-container {
    .body {
      margin-bottom: 0;
    }

    .footer {
      padding-bottom: 0;
    }
  }
}

.add-wallet-modal {
  form {
    .textfield-item-container {
      padding: 0 30px;
    }
  }

  .footer {
    flex-direction: column;
    align-items: center;
  }

  .MuiAlert-root {
    border: 0;
  }
}