// Color
$black: #000;
$white: #fff;
$red-1: #ed1f24;
$light-pink-1: #fd748e;
$light-orange-1: #f79679;
$green-1: #16c46a;
$sky-blue-1: #1e8bd1;
$light-sky-blue-1: #adfaff;
$blue-1: #016ce9;
$blue-2: #3070de;
$purple-1: #7b79eb;
$light-purple-1: #efeef6;
$light-purple-2: #f1f0fb;
$gray-1: #ccc;
$light-gray-1: #e1dfec;
$light-gray-2: #ececec;
$light-gray-3: #efeef6;

// Text Shade
$text-color-1: #333;
$text-color-2: #555;
$text-color-3: #777;

// Default
$Noto: 'Noto Sans KR', sans-serif;

// PX to Rem
$px-2: 0.125rem;
$px-4: 0.25rem;
$px-6: 0.375rem;
$px-8: 0.5rem;
$px-10: 0.625rem;
$px-12: 0.75rem;
$px-14: 0.875rem;
$px-16: 1rem;
$px-18: 1.125rem;
$px-20: 1.25rem;
$px-22: 1.375rem;
$px-24: 1.5rem;
$px-26: 1.625rem;
$px-28: 1.75rem;
$px-30: 1.875rem;
$px-32: 2rem;
$px-34: 2.125rem;
$px-36: 2.25rem;
$px-38: 2.375rem;
$px-40: 2.5rem;
$px-42: 2.625rem;
$px-44: 2.75rem;
$px-46: 2.875rem;
$px-48: 3rem;
$px-50: 3.125rem;
$px-60: 3.75rem;
$px-70: 4.375rem;
$px-80: 5rem;

// Font Weight
$font-weight-l: 400;
$font-weight-r: 400;
$font-weight-m: 500;
$font-weight-b: 700;
$font-weight-bb: 900;
