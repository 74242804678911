@import "../../layout/variables";
@import "../../layout/mixins";

.alert-modals.conditional-modal {
  .paper {
    .icon {
      fill: $red-1;
    }

    .title {
      margin: $px-12 auto;
    }

    .denied-btn {
      background-color: $text-color-3;
      color: $white;

      &:hover {
        background-color: darken($text-color-3, 13%);
      }
    }
  }
}
