@import "../../layout/variables";
@import "../../layout/mixins";

.packages-page {
  .page-title {
    margin-bottom: $px-22;
  }

  .table-wrapper{

    .image-url{
      color: $blue-2;
      cursor: pointer;
    }
  
    .action-cell{
      width: 100%;
      text-align: right;
      
      button + button{
        margin-left: 10px;
      }
      button{
        line-height: 1.43;
        min-width: 70px;
        font-size: $px-14;
        padding: 4px;

        &.MuiButton-contained.Mui-disabled{
          color: $white;
          background-color: rgba(0, 0, 0, 0.2);
        }
      }
    }
  
    .publish-cell{
      width: 100%;
      .MuiFormControlLabel-root{
        margin: 0 auto;
      }
      .root-switch {
        width: 30px;
        height: 16px;
        padding: 0;
        margin: 8px;
      }
      .switch-base, 
      .switch-base.Mui-disabled {
        padding: 1px;
        color: darken($white, 2%);
  
        &.checked {
          transform: translateX(14px);
          color: $white;
          + .track {
            background-color: $purple-1;
            opacity: 1;
            border: none;
          }
        }
        &.focus-visible .thumb {
          color: $purple-1;
          border: 6px solid $white;
        }
      }
      .thumb {
        width: 14px;
        height: 14px;
      }
      .switch-base.Mui-disabled + .track,
      .track {
        border-radius: 999px;
        border: 1px solid #ccc;
        background-color: #ccc;
        opacity: 1;
        transition: .2s background-color, .2s border,
      }
    }

    @media (max-width: 1320px) {
      .table-container{
        .header{
          flex-direction: column;

          .table-search{
            max-width: none;
          }

          .right-container{
            margin-left: 0;
            margin-top: 16px;
          }
        }
      }
    }
  }
}

.pop-loader{
  .MuiPopover-paper{
    padding: 8px;
    box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.04);
  }
}

.package-modal{
  overflow: auto;

  .MuiBackdrop-root{
    background-color: rgba(0, 0, 0, 0.8);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
  }


  .paper-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 640px;
  }

  .paper {
    background-color: $white;
    border-radius: $px-10;
    box-shadow: 0 0 20px rgba($black, 0.2);
    padding: 0;
    width: 800px;
    text-align: center;
    box-sizing: border-box;
  }

  .header{
    padding: $px-24 $px-30 $px-16;
    border-bottom: 1px solid rgba($black, 0.12);

    .title{
      font-size: $px-20;
      font-weight: 500;
      margin: 0;
    }
  }
  .body{
    padding: $px-30 $px-30 $px-14;
    .form-control{
      flex-direction: row;
      align-items: center;
      width: 100%;

      + .form-control{
        margin-top: $px-10;
      }
  
      label{
        font-weight: 400;
        font-size: $px-16;
        min-width: 90px;
        margin-right: $px-20;
        text-align: left;
      }

      .select-apps{
        border-radius: 999px;
        text-align: left;

        .empty-select{
          font-style: normal;
          opacity: 0.4;
        }

        .MuiSelect-select:focus{
          background: none;
        }

        .MuiInputBase-input{
          font-weight: 400;
        }
  
        .MuiOutlinedInput-input{
          padding: 10px $px-46 11px $px-20;
          position: relative;

          &:before{
            content: '';
            position: absolute;
            height: 100%;
            width: 1px;
            top: 0;
            right: 46px;
            background-color: rgba(0, 0, 0, 0.20);
          }
        }

        &.Mui-focused {
          .MuiOutlinedInput-notchedOutline {
            border-width: 1px;
          }
        }

        .MuiSelect-icon{
          right: 13px;
          top: calc(50% - 10px);
          width: $px-20;
          height: $px-20;
        }
      }

      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-width: 1px;
      }
  
      .text-field{
        width: 100%;

        .MuiInputBase-input{
          font-weight: 400;
        }
  
        .MuiOutlinedInput-input{
          padding: 10px $px-10 11px $px-20;
        }
  
        .MuiOutlinedInput-root{
          border-radius: 999px;
        }
      }

      .number-field{
        .MuiInputBase-input::-webkit-inner-spin-button,
        .MuiInputBase-input::-webkit-outer-spin-button { 
          -webkit-appearance: none;
        }
      }

      &.currency-form{
        align-items: start;

        .block{
          max-width: 170px;
          overflow: hidden;
          position: relative;
        }

        .number-field{
          .MuiOutlinedInput-root{
            border-radius: 20px;
          }
          .MuiOutlinedInput-input{
            padding-top: 62px;
            padding-left: 20px;
          }
        }

        .select-currency{
          position: absolute;
          top: 0;
          z-index: 1;

          &.MuiInput-underline:after,
          &.MuiInput-underline:before,
          &.MuiInput-underline:hover:not(.Mui-disabled):before{
            border: none
          }
          .MuiSelect-select.MuiSelect-select {
            margin-left: 18px;
            padding: 12px 22px 10px 0;
            display: flex;
            align-items: center;
            color: lighten($text-color-3, 10%);;

            img{
              margin-right: 6px;
              width: 20px;
            }
          }
          .MuiSelect-select:focus {
            background-color: transparent;
          }
          .MuiSelect-icon{
            right: 0;
            top: calc(50% - 9px);
            width: $px-20;
            height: $px-20;
          }
        }
      }

      &.upload-form{
        align-items: start;
        margin-top: $px-20;
        position: relative;
        padding-top: $px-20;
        border-top: 1px solid rgba(0,0,0,0.1);
      }

      &.description-form{
        align-items: start;
        .text-editor{
          width: 100%;
          caret-color: $black;
        }

        .editor-wrapper{
          border: 1px solid rgba(0, 0, 0, 0.23);
          border-radius: 12px;
          overflow: hidden;

          &:hover{
            border-color: rgba(0, 0, 0, 0.87);
          }

          &.error,
          &.error:hover{
            border-color: $red-1;
          }
        }

        .rdw-editor-toolbar{
          border-radius: 0;
          border-top-right-radius: 12px;
          border-top-left-radius: 12px;
          border: none;
          border-bottom: 1px solid rgba(0, 0, 0, 0.23);
          padding: 0 14px;
          margin: 0;

          .rdw-option-wrapper{
            padding: 2px;
            min-width: 20px;
            height: 20px;
            margin: 0 1px;
          }

          .rdw-inline-wrapper, .rdw-list-wrapper{
            margin-top: 6px;
          }
          .rdw-inline-wrapper{
            padding-right: 6px;
            border-right: 1px solid rgba(0, 0, 0, 0.23);
            margin-right: 6px;
          }

          .rdw-option-wrapper{
            border-color: transparent;

            &.rdw-option-active {
              border: 1px solid darken($white, 7%);
              box-shadow: none;
            }

            &:hover{
              background-color: darken($white, 3%);
              box-shadow: none;
            }
          }
        }
        
        .editor{
          padding: 6px 6px 6px 18px;
          min-height: 70px;
          max-height: 130px;

          ul, ol{
            margin: 0;
            margin-bottom: $px-8;
          }
          .public-DraftStyleDefault-block{
            margin: 0;
          }
          .public-DraftEditorPlaceholder-root{
            color: lighten($text-color-1, 50%);
          }
        }
      }

      .faded-container{
        min-height: 140px;
      }

      .dropzone-wrapper{
        position: relative;
        width: 100%;

        .additional-text{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
          font-size: $px-14;
          font-weight: 500;
          padding-top: 62px;
          margin: 0;
          color: lighten($text-color-3, 10%);
          pointer-events: none;
        }
      }

      .drop-image{
        border: 1px solid transparent;
        min-height: 140px;
        background-color: $light-purple-2;
        position: relative;
        background-image: url("data:image/svg+xml,%3csvg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%' height='100%' fill='none' rx='6' ry='6' stroke='rgba(0,0,0,0.2)' stroke-width='2' stroke-dasharray='6%2c 10' stroke-dashoffset='23' stroke-linecap='square'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        border-radius: 6px;

        &.error,
        &.error:hover{
          border-color: $red-1;
        }

        &:hover{
          background-image: none;
          border-color: rgba(0, 0, 0, 0.87);
        }

        // &:focus{
        //   border-color: $purple-1;
        //   border-width: 2px;
        // }

        .MuiDropzoneArea-textContainer{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
          padding-bottom: 24px;
          pointer-events: none;

          p{
            color: $purple-1;
            font-size: $px-14;
            margin: 0;
            margin-bottom: $px-16;
            font-weight: 400;
          }
          svg{
            width: 16px;
            height: 16px;
            fill: $purple-1;
            margin-bottom: $px-2;
          }
        }

        .priview-container{
          width: 100%;
          height: 100%;
          margin: 0;
          display: block;
          position: absolute;

          .priview-item{
            padding: 0;
            width: 98%;
            max-width: none;
            position: relative;
            margin: 0 auto;
            top: 50%;
            transform: translateY(-50%);
            background-color: $light-purple-2;

            &:hover{
              img{
                opacity: 1;
              }
            }
            
            img{
              height: 120px;
              vertical-align: middle;
              border-radius: 0;
              box-shadow: none;
            }
            button{
              opacity: 1;
              top: auto;
              right: 12px;
              bottom: 2px;
              box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
              background-color: $purple-1;

              svg{
                fill: $white;
              }
            }
          }
        }
      }
    }
  }
  .footer{
    padding: $px-14 $px-30 $px-30;

    button{
      min-width: 150px;
      padding: 8px 16px;
    }

    button + button{
      margin-left: 10px;
    }

    .submit-btn{
      position: relative;
  
      .text{
        position: relative;
        right: 0;
        transition: right .4s ease-in-out;
      }
  
      &.Mui-disabled .text{
        right: 10px;
      }
  
      &.empty-fields{
        &.Mui-disabled .text{
          right: 0;
        }
      }
      
      .loader{
        position: relative;
      }
  
      &.MuiButton-contained.Mui-disabled{
        color: rgba($white, 0.8);
        background-color: lighten($purple-1, 5%)
      }
    }

    .exit-btn {
      background-color: $text-color-3;
      color: $white;

      &:hover {
        background-color: darken($text-color-3, 13%);
      }

      &.MuiButton-contained.Mui-disabled{
        color: rgba($white, 0.8);
        background-color: lighten($text-color-3, 20%);
      }
    }

    .error-message{
      padding: 20px 0 0;
      border-color: transparent;
      justify-content: center;
      color: darken($red-1, 9%);
      
      .MuiAlert-icon{
        color: $red-1;
      }
    }
  }
}

.preview-package{
  .paper{
    width: 560px;
    border: 3px solid $purple-1;
  }

  .header{
    display: flex;
    align-items: center;
    border: none;
    padding: $px-20 $px-18 $px-10 $px-28;

    button{
      margin-left: auto;
      padding: 8px;
    }
  }

  .body{
    padding: 0 $px-40 $px-30;
    text-align: center;

    img{
      max-height: 460px;
      vertical-align: middle;
    }
  }
}

.select-dd{
  margin-top: 6px;
  li{
    font-weight: 400;
    color: lighten($text-color-3, 6%);
    &.MuiListItem-gutters{
      padding: 6px 30px 6px 20px;
    }
  }
  .MuiPaper-rounded{
    border-radius: 12px;
    box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.12);
  }
  .MuiListItem-button:hover,
  .MuiListItem-root.Mui-selected, 
  .MuiListItem-root.Mui-selected:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }
  .MuiListItem-root.Mui-selected{
    color: $text-color-1;
  }
}

.currency-dd{
  margin-top: 0;
  li{
    img{
      margin-right: 6px;
      width: 20px;
    }
  }
}