@import '../../layout/variables';
@import '../../layout/mixins';

.top-nav{
  height: 70px;

  &.MuiAppBar-root{
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }

  @media (min-width: 600px){
    .MuiToolbar-regular{
      min-height: 70px;
    }
  }

  .logo{
    width: 154px;
    margin-right: 60px;
  }

  .left{
    .menuButton {
      text-transform: none;
      padding: 6px 20px;
      font-weight: 400;
      font-size: $px-16;
      margin-right: $px-20;
      color: $white;
  
      &.active{
        color: $light-sky-blue-1;
      }
  
      &.Mui-disabled{
        color: $white;
      }
    }
  }

  .right{
    margin-left: auto;
    .user-dropdown{
      display: flex;
      align-items: center;
      justify-content: center;

      .user-email{
        font-size: $px-12;
        max-width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
      }
      .user-dropdown-btn{
        padding: $px-8;

        .MuiIconButton-label{
          background: $white;
          border-radius: 999px;
          overflow: hidden;
          width: 40px;
          height: 40px;
        }
      }
      .user-icon{
        fill: $light-gray-1;
        position: relative;
        width: 34px;
        top: 4px;
      }
    }
  }

  @media (max-width: 1260px){
    .left{
      .menuButton {
        margin-right: 1vw;
      }
    }

    .right{
      .user-dropdown{
        .user-email{
          max-width: 16vw;
        }
      }
    }
  }

  @media (max-width: 1060px){
    .left{
      .menuButton {
        padding: 6px 1.6vw;
      }
    }
    .right{
      .user-dropdown{
        .user-email{
          max-width: 12vw;
        }
      }
    }
  }

  // MOBILE
  .hamburger{
    margin-left: auto;
    color: $white;
  }
  
}

// MOBILE DRAWER MODAL
.menu-drawer{

  .menuButton-mobile{
    color: $text-color-2;
    text-decoration: none;
    display: block;
    padding: $px-10 $px-20;

    &[disabled]{
      pointer-events: none;
      color: $text-color-3;
    }

    &:hover:not([disabled]){
      text-decoration: none;
      background: rgba(0,0,0,.03);
    }
  
    &.active{
      color: $purple-1;
    }
  }
}


// USER MENU MODAL
.user-menu{
  .MuiPaper-root{
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }

  .MuiMenuItem-root{
    font-weight: 400;
    color: $text-color-2;
  }
}