// font
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");
// slick
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
// swiper
@import "swiper/swiper.scss";
@import "swiper/components/a11y/a11y.scss";
@import "swiper/components/controller/controller.scss";
@import "swiper/components/effect-coverflow/effect-coverflow.scss";
@import "swiper/components/effect-cube/effect-cube.scss";
@import "swiper/components/effect-fade/effect-fade.scss";
@import "swiper/components/effect-flip/effect-flip.scss";
@import "swiper/components/lazy/lazy.scss";
@import "swiper/components/navigation/navigation.scss";
@import "swiper/components/pagination/pagination.scss";
@import "swiper/components/scrollbar/scrollbar.scss";
@import "swiper/components/thumbs/thumbs.scss";
@import "swiper/components/zoom/zoom.scss";
// text-editor
@import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// layout
@import "./variables";
@import "./mixins";
@import "./overwrite";
@import "./important";

// global
html,
body {
  background-color: $white;
  max-width: 100%;
  margin: 0;

  font-family: $Noto;
  font-size: 16px;
  font-weight: 400;
  color: $text-color-1;
  caret-color: transparent;

  min-width: 960px;
}

input{
  caret-color: $black;
}

body::-webkit-scrollbar {
    @include scrollbar;
}

body::-webkit-scrollbar-track {
    @include scrollbar-track;
}

body::-webkit-scrollbar-thumb {
    @include scrollbar-thumb;
}

.MuiScopedCssBaseline-root.baseline {
  font-family: $Noto;
  font-size: 16px;
  font-weight: 400;
  color: $text-color-1;
}

.wrapper {
  min-height: 100vh;
  position: relative;

  .main {
    height: 100vh;
    padding-top: 70px;
  }
}

@media (max-width: 959px) {
  .wrapper {
    min-height: 99vh;
  
    .main {
      height: 99vh;
      padding-top: 62px;
    }
  }
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

// LAZY LOADING =========================================
.lazy-load {
  height: 100vh;
  background: #fafafa;
  top: 0;
  left: auto;
  right: 0;
  position: fixed;
  z-index: 99999;
  width: 100%;
  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    left: 0;
    right: 0;
  }
}

// SCROLL BAR =========================================
.custom-scroll::-webkit-scrollbar {
  @include scrollbar;
}

.custom-scroll::-webkit-scrollbar-track {
  @include scrollbar-track;
}

.custom-scroll::-webkit-scrollbar-thumb {
  @include scrollbar-thumb;
}
