@import '../../layout/variables';
@import '../../layout/mixins';

.login-page,
.forgot-password-page,
.reset-password-page{
  &.page-wrapper{
    background-color: $light-purple-1;
    min-height: 700px;
    position: relative;
    height: 100vh;
  }

  .form-box{
    padding: $px-70 $px-60;
    border-radius: $px-20;
    background: $white;
    width: 480px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    text-align: center;
    border: 1px solid $purple-1;
    box-shadow: 0px 4px 40px rgba($purple-1, 0.1);
    
    form{
      text-align: left;
    }

    .form-title{
      color: $purple-1;
      font-size: $px-24;
      margin-top: $px-20;
      margin-bottom: $px-80;
      font-weight: 500;
    }

    .dappstore-logo{
      max-width: 210px;
      margin: 0 auto;
      margin-bottom: $px-40;
    }
  }

  .btn-submit{
    width: 100%;
    border-radius: 999px;
    font-size: $px-20;
    text-transform: none;
    font-weight: 400;
    padding: $px-12;
    padding-bottom: $px-14;
    margin-bottom: $px-20;
    position: relative;

    .text{
      position: relative;
      right: 0;
      transition: right .4s ease-in-out;
    }

    &.Mui-disabled .text{
      right: 10px;
    }

    &.empty-fields{
      &.Mui-disabled .text{
        right: 0;
      }
    }
    
    .loader{
      position: relative;
      top: 2px;
    }

    &.MuiButton-contained.Mui-disabled{
      color: rgba($white, 0.8);
      background-color: lighten($black, 72%)
    }
  }

  .form-input,
  .form-input input{
    border-radius: 999px;
  }

  .form-input input{
    height: auto;
    padding: $px-14 $px-34;
    padding-bottom: $px-16;
    line-height: 1;
    font-weight: 400;
  }

  .MuiFormGroup-root{
    margin-bottom: $px-16;
  }

  .MuiFormGroup-root label{
    font-size: $px-18;
    margin: $px-10 $px-20;
    color: $text-color-1;
    font-weight: 400;
  }

  .MuiFormGroup-root .MuiFormControlLabel-root{
    margin: 0;
    position: relative;
    left: -10px;

    .MuiCheckbox-root {
      color: rgba(0, 0, 0, 0.26);

      &.Mui-checked{
        color: $light-gray-2;
        
        .MuiIconButton-label{
          position: relative;

          &::after{
            content: "";
            background: $purple-1;
            position: absolute;
            width: $px-14;
            height: $px-14;
            top: 5px;
            left: 5px;
            z-index: -1;
          }
        }
      }
    }
    .MuiTypography-root{
      @include remove-highlight;
      position: relative;
      bottom: 1px;
    }
  }

  .btn-forgot{
    color: $text-color-2;
    text-decoration: none;
    font-weight: 400;
    &:hover{
      text-decoration: underline;
      color: $purple-1;
    }
  }
}