@import '../../layout/variables';
@import '../../layout/mixins';

.loader{
  display: flex;
  justify-content: center;
  align-items: center;

  .loader-icon{
    margin: 0 auto;

    linearGradient{
      stop[offset="0"]{
        stop-color: #E5E4FB;
      }
      stop[offset="1"]{
        stop-color: #9594EF;
      }
    }
  }

  &.colored-bg{
    .loader-icon{
      linearGradient{
        stop[offset="0"]{
          stop-color: rgba($white, 0.02);
        }
        stop[offset="1"]{
          stop-color: rgba($white, 0.8);
        }
      }
    }
  }
}