@import "../../../../layout/variables";
@import "../../../../layout/mixins";

.select-input {
  border-radius: 999px;
  text-align: left;

  .empty-select {
    font-style: normal;
    opacity: 0.4;
  }

  .MuiSelect-select:focus {
    background: none;
  }

  .MuiInputBase-input {
    font-weight: 400;
  }

  .MuiOutlinedInput-input {
    padding: 10px $px-46 11px $px-20;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 1px;
      top: 0;
      right: 46px;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-width: 1px;
    }
  }

  .MuiSelect-icon {
    right: 13px;
    top: calc(50% - 10px);
    width: $px-20;
    height: $px-20;
  }
}

.editor-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 12px;
  overflow: hidden;

  &:hover {
    border-color: rgba(0, 0, 0, 0.87);
  }

  &.error,
  &.error:hover {
    border-color: $red-1;
  }
}

.editor {
  padding: 6px 6px 6px 18px;
  min-height: 70px;
  max-height: 130px;

  ul,
  ol {
    margin: 0;
    margin-bottom: $px-8;
  }
  .public-DraftStyleDefault-block {
    margin: 0;
  }
  .public-DraftEditorPlaceholder-root {
    color: lighten($text-color-1, 50%);
  }
}

.tooltip-text-style {
  font-size: 13px;
  font-weight: normal;
  color: #fff;
}