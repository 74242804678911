@import '../../layout/variables';
@import '../../layout/mixins';

.wrapper{

  .main{

    .page-wrapper{
      display: flex;
    }
    .page-content{
      padding: $px-30;
      background: $light-gray-3;
      margin-left: 230px;
      width: 100%;
      height: calc( 100vh - 70px );
      overflow: auto;

      .page-title{
        display: flex;
        align-items: flex-end;

        h2{
          font-size: $px-30;
          margin: 0;
        }

        .right{
          margin-left: auto;

          button{
            padding: 8px 16px;
            min-width: 150px;
            font-weight: 400;

            + button{
              margin-left: $px-8;
            }

            .icon{
              fill: $white;
              width: $px-16;
              margin-right: 8px;
            }

            &.MuiButton-outlined{
              border-width: 2px;
              border-color: $purple-1;
              background-color: $white;
              padding: 6px 16px;

              .icon{
                fill: $purple-1;
                width: 1rem;
              }

              &:hover{
                border-color: rgba(123, 121, 235, 0.5);
              }
            }
          }
        }
      }
    }
  }
}