@import "../../layout/variables";
@import "../../layout/mixins";

.users-page {
  .page-title {
    margin-bottom: $px-22;
  }

  .card-paper {
    padding: $px-18;
    border-radius: $px-12;
    background-repeat: no-repeat;
    background-position: center right 30px;
    background-size: 90px;
    background-image: url("../../assets/images/dappstore-icon-gray.svg");

    .title {
      font-size: $px-18;
      font-weight: 500;
      display: flex;
      align-items: center;
      margin-bottom: $px-10;

      .icon {
        display: inline-block;
        vertical-align: middle;
        background-color: $purple-1;
        padding: $px-6 $px-6 5px;
        border-radius: $px-10;
        margin-right: $px-10;

        svg {
          width: $px-24;
        }
      }
    }

    .value {
      font-size: $px-42;
      font-weight: 700;
    }
  }

  .table-container{
    .wallet-address{
      color: $blue-2;
    }
  }

  @media (max-width: 1200px) {
    .table-container{
      .header{
        flex-direction: column;

        .table-search{
          max-width: none;
        }

        .right-container{
          margin-left: 0;
          margin-top: 16px;
        }
      }
    }
  }
}
