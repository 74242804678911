@import "../../layout/variables";
@import "../../layout/mixins";

.alert-modals {
  overflow: auto;

  .MuiBackdrop-root{
    background-color: rgba(0, 0, 0, 0.8);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
  }

  .paper-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 500px;
  }

  .paper {
    background-color: $white;
    border-radius: $px-10;
    border: 3px solid $purple-1;
    box-shadow: 0 0 20px rgba($black, 0.2);
    padding: $px-50;
    width: 560px;
    text-align: center;
    box-sizing: border-box;

    .title{
      margin: 6px auto $px-12;
      vertical-align: middle;
      font-size: 40px;
      color: $text-color-1;
    }

    .desc{
      margin-top: 0;
      font-size: $px-18;
      color: $black;
    }

    .icon {
      max-height: 96px;
    }

    .MuiButton-root{
      min-width: 130px;
      padding: 8px 16px;
      margin-top: $px-24;
    }

    button + button{
      margin-left: 10px;
    }
  }
}
