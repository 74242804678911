@import "../../layout/variables";
@import "../../layout/mixins";

.faded-container {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;

  .loader{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    width: 100%;
  }
}